import { React, useState, useEffect, } from 'react';
import {
  ChakraProvider,
  Box,
  Link,
  Grid,
  theme,
  Button,
  Flex,
  IconButton
} from '@chakra-ui/react';
import bgDesktop from './bg-desktop.mp4';
import bgMobile from './bg-mobile.png';
import headerLogo from './textlogo.png';
import { Icon } from '@chakra-ui/react'
import { FaTwitter, FaDiscord, FaBook, FaBlogger } from 'react-icons/fa';
import bgDesktopLoading from './loadingbg.png';


function App() {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl">


        {width > 800 ?

          (
            <Grid minH="100vh" style={{
              backgroundColor: "#000", backgroundImage: `url(${bgDesktopLoading})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#000"
            }}>
              <Box style={{ zIndex: 99, marginTop: "200px", marginLeft: "100px", marginRight: "100px" }}>
                <img style={{ zIndex: 99, maxWidth: "80vw", width: "600px", marginBottom: "20px" }} src={headerLogo} />
                <p style={{ zIndex: 99, color: "#3aff16", fontSize: 24, marginBottom: "20px", fontFamily: "'Orbitron', sans-serif", letterSpacing: "8px" }}>The Native Stablecoin of Fuel</p>
                <Link href='https://docs.fluidprotocol.xyz/fluid-protocol-community/' isExternal > <Button variant="outline" color="#E2E8F0"> <Icon as={FaBook}></Icon> &nbsp; Documentation </Button> </Link>
              </Box>
              <Box style={{ position: "absolute", bottom: 25, left: 0, right: 0, width: "100px", margin: "0 auto" }}>
                <Box style={{ maxWidth: "100px", width: "100px", display: "block", justifySelf: "center", }}>
                  <Flex style={{ justifyContent: "space-between" }} gap={10}>
                    <IconButton variant="link" onClick={() => window.open("https://discord.com/invite/fSZKERH9YJ", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaDiscord} />} />
                    <IconButton variant="link" onClick={() => window.open("https://twitter.com/Fluid_Protocol", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaTwitter} />} />
                    <IconButton variant="link" onClick={() => window.open("https://fluidprotocol.substack.com/", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBlogger} />} />
                  </Flex>
                </Box>
              </Box>
              <video poster={bgDesktopLoading} autoPlay={true} muted={true} loop={true} style={{ minHeight: "100%", minWidth: "100%", objectFit: "cover", width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0 }}>
                <source src={bgDesktop} type="video/mp4" />
              </video>
            </Grid>
          )
          :
          (
            <Box minH="100vh" style={{
              backgroundImage: `url(${bgMobile})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#000"
            }}>
              <img style={{ zIndex: 99, maxWidth: "80vw", width: "400px", paddingTop: "40px", margin: "0 auto", marginBottom: "20px" }} src={headerLogo} />
              <p style={{ zIndex: 99, color: "#3aff16", fontSize: 12, textAlign: "center", marginBottom: "20px", fontFamily: "'Orbitron', sans-serif", letterSpacing: "4px" }}>The Native Stablecoin of Fuel</p>
              <Link href='https://docs.fluidprotocol.xyz/fluid-protocol-community/' isExternal > <Button style={{ margin: "0 auto", display: "block" }} variant="outline" color="#fff"> <Icon as={FaBook}></Icon> &nbsp; Documentation </Button> </Link>
              <Box className="safari_only" style={{ position: "absolute", bottom: 25, left: 0, right: 0, width: "100px", margin: "0 auto" }}>
                <Box style={{ maxWidth: "100px", width: "100px", display: "block", justifySelf: "center", }}>
                  <Flex style={{ justifyContent: "space-between" }} gap={4}>
                    <IconButton variant="link" onClick={() => window.open("https://discord.com/invite/fSZKERH9YJ", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaDiscord} />} />
                    <IconButton variant="link" onClick={() => window.open("https://twitter.com/Fluid_Protocol", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaTwitter} />} />
                    <IconButton variant="link" onClick={() => window.open("https://fluidprotocol.substack.com/", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBlogger} />} />
                  </Flex>
                </Box>
              </Box>
            </Box>
          )

        }


      </Box>
    </ChakraProvider>
  );
}

export default App;
